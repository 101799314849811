import homeRouter from './home-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/about',
    component: () => import('@/view/home/home'),
    children: [...homeRouter],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login/login'),
  },
  {
    path: '/wxindex',
    name: 'wxindex',
    component: () => import('@/view/wxindex/wxindex'),
    meta: {
      title: '票村',
      keepAlive: true, // 需要被缓存
    },
  },
  {
    redirect: '/404',
    path: '/:pathMatch(.*)',
  },
]

export default routes
